import { POLARITY } from 'api/src/common/enums'
import type { SentimentScores } from 'api/src/common/types'
import { checkSentimentScorePolarity } from 'api/src/common/utils'
import dayjs from 'dayjs'
import { sum, uniq } from 'ramda'
import {
  EmailFlagStatus,
  Landlord,
  SentimentStatusOptions,
  type SentimentAnalysisRatingOptions,
} from 'types/graphql'

import { SelectionType } from '../Library/SelectWithSort/SelectWithSort'

import {
  FilterByOption,
  SASortOptions,
  SentimentAnalysisLabelThemes,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
  type FilterByGroupOption,
  SentimentAnalysisRatingSelectOptions,
} from './SentimentAnalysisHelper'

export const validateTokenExpiry = (expiryDate: Date): boolean => {
  return dayjs().utc().isBefore(dayjs(expiryDate).utc())
}

export const getRatingStatus = (
  csiRating: number,
): SentimentAnalysisLabelThemes => {
  let labelTheme = SentimentAnalysisLabelThemes.danger
  if (checkSentimentScorePolarity(csiRating) === POLARITY.NEUTRAL)
    labelTheme = SentimentAnalysisLabelThemes.warning
  if (checkSentimentScorePolarity(csiRating) === POLARITY.POSITIVE)
    labelTheme = SentimentAnalysisLabelThemes.success

  return labelTheme
}

interface FormatUsers {
  id: number
  emails?: {
    csiRating?: number
    flaggedStatus?: EmailFlagStatus
    sentimentScores?: SentimentScores
  }[]
  landlordsWithEmailsAndSentimentScores?: any[]
  user?: any
  unprocessedEmailsCount?: number
  totalEmailsCount?: number
}

export const formatESIRecords = <T extends FormatUsers>(users: T[]) => {
  const usersWithContacts = users?.filter(
    (contact) => contact?.landlordsWithEmailsAndSentimentScores?.length > 0,
  )

  const activeMembers = usersWithContacts?.map((contact) => {
    const userLandlordScore =
      contact?.landlordsWithEmailsAndSentimentScores?.map(
        (landlord) => landlord?.csiRating,
      )
    const overallMemberCSIRating =
      Math.round((sum(userLandlordScore) / userLandlordScore?.length) * 100) /
      100

    const memberDetails: Landlord = {
      id: contact?.id,
      csiRating: overallMemberCSIRating,
      name: contact?.user?.name,
      emailCensored: contact?.user?.position,
      emails: [],
      unprocessedEmailsCount: contact?.unprocessedEmailsCount,
      totalEmailsCount: contact?.totalEmailsCount,
    }

    const allEmails = contact?.landlordsWithEmailsAndSentimentScores?.flatMap(
      (landlord) => {
        return landlord.emails
      },
    )

    return { ...contact, memberDetails, emails: allEmails }
  })

  return activeMembers
}

export const sortActiveESIRecords = <
  T extends {
    name?: string
    user?: {
      name?: string
    }
    csiRating?: number
    memberDetails?: any
    emails?: any[]
  },
>(
  contacts: T[],
  sortByValues: SelectionType,
  isMemberView = false,
) => {
  const sortedContacts = [...contacts].sort((a, b) => {
    const sortTrue = sortByValues.asc ? 1 : -1
    const sortFalse = sortByValues.asc ? -1 : 1

    if (sortByValues.value === SASortOptions.name) {
      if (isMemberView) {
        return a?.name > b?.name ? sortTrue : sortFalse
      } else {
        return a?.user.name > b?.user.name ? sortTrue : sortFalse
      }
    }

    if (sortByValues.value === SASortOptions.esi) {
      if (isMemberView) {
        return a?.csiRating > b?.csiRating ? sortTrue : sortFalse
      } else {
        return a?.memberDetails?.csiRating > b?.memberDetails?.csiRating
          ? sortTrue
          : sortFalse
      }
    }

    if (sortByValues.value === SASortOptions.latest) {
      // Emails are already ordered by newest
      const emailFieldName = isMemberView
        ? 'emails'
        : 'landlordsWithEmailsAndSentimentScores'
      const newestEmailA = a?.[emailFieldName][0]
      const newestEmailB = b?.[emailFieldName][0]

      return newestEmailA.receivedAt < newestEmailB.receivedAt
        ? sortTrue
        : sortFalse
    }

    return 0
  })

  return sortedContacts
}

const getPolarityFromScore = (
  score?: number,
): 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL' => {
  if (score === null || score === undefined) return 'NEUTRAL'
  if (score >= 6) return 'POSITIVE'
  if (score <= 4) return 'NEGATIVE'
  return 'NEUTRAL'
}

export const handleFilterEmailByRating = <
  T extends {
    sentimentScores?: SentimentScores | any
  },
>(
  email: T,
  filterESIRating: SentimentAnalysisRatingOptions,
) => {
  if (filterESIRating === SentimentAnalysisRatingSelectOptions.ALL) {
    return true
  }

  const rawNpsScore = email?.sentimentScores?.nps
  if (rawNpsScore === null || rawNpsScore === undefined) {
    return false
  }
  const npsScore = Number(rawNpsScore)
  const ratingPolarity = getPolarityFromScore(npsScore)
  return ratingPolarity === filterESIRating
}

export const filterESIRecords = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      sentimentScores?: SentimentScores | any
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      name: string
      csiRating?: number
      sentimentStatus?: SentimentStatusOptions
      emails?: {
        flaggedStatus?: EmailFlagStatus
        sentimentScores?: SentimentScores
      }[]
    }[]
    // membershipGroups: Only used on the Admin view
    membershipGroups?: { membershipGroup?: { id: number; name?: string } }[]
  },
>(
  contacts: T[],
  filterOptions: FilterByOption[],
  sortByValues: SelectionType,
  filterESIRating: SentimentAnalysisRatingOptions,
  filterByFlag: SentimentAnalysisFlagOptions | EmailFlagStatus,
  filterByClientStatus: SentimentStatusSelectOptions | SentimentStatusOptions,
  isMemberView = false,
) => {
  const hasSomeLandlordsSelected = filterOptions.some(
    (option) => option.type === 'LANDLORD',
  )
  const hasSomeMembersSelected = filterOptions.some(
    (option) => option.type === 'MEMBER',
  )
  const hasSomeGroupsSelected = filterOptions.some(
    (option) => option.type === 'GROUP',
  )

  const filterGroups = filterOptions.filter(
    (option) => option.type === 'GROUP',
  ) as FilterByGroupOption[]

  const allSelectedGroupAndChildIds = uniq(
    filterGroups.flatMap((group) => {
      return group.childGroupIds.concat(group.id)
    }),
  )

  const contactsFilteredBySelection =
    filterOptions.length === 0
      ? contacts
      : contacts.filter((contact) => {
          // Selected by Landlord
          if (hasSomeLandlordsSelected) {
            const isSelectedOptionLandlord = filterOptions.some(
              (option) =>
                option.id === contact.id && option.type === 'LANDLORD',
            )
            if (isSelectedOptionLandlord) return true
          }

          // Selected by Member
          if (hasSomeMembersSelected) {
            const isSelectedOptionMember = filterOptions.some(
              (option) => option.id === contact.id && option.type === 'MEMBER',
            )
            if (isSelectedOptionMember) return true
          }

          // Selected Member by Group
          const doesContactHaveGroups =
            !!contact.membershipGroups && contact.membershipGroups.length > 0

          if (hasSomeGroupsSelected && doesContactHaveGroups) {
            const contactGroups = contact.membershipGroups
              .map((group) => group.membershipGroup)
              .flatMap((group) => group)
              .filter((group) => !!group && group.name)

            const isInSelectedGroup = allSelectedGroupAndChildIds.some(
              (selectedGroupId) =>
                contactGroups.some(
                  (contactGroup) => contactGroup.id === selectedGroupId,
                ),
            )
            if (isInSelectedGroup) return true
          }

          return false
        })

  const contactsFilteredByESIRating =
    filterESIRating === SentimentAnalysisRatingSelectOptions.ALL
      ? contactsFilteredBySelection
      : contactsFilteredBySelection.filter((contact) => {
          const emails = isMemberView
            ? contact.emails
            : (contact.landlordsWithEmailsAndSentimentScores?.flatMap(
                (landlord) => landlord.emails ?? [],
              ) ?? [])

          const emailsFilteredByRating = emails?.filter((email) =>
            handleFilterEmailByRating(email, filterESIRating),
          )
          if (emailsFilteredByRating.length > 0) {
            return true
          }
          return false
        })

  const formattedContacts = isMemberView
    ? contactsFilteredByESIRating
    : formatESIRecords(contactsFilteredByESIRating)

  const contactsFilteredByFlag = filterEmailsByFlag(
    filterByFlag as SentimentAnalysisFlagOptions,
    formattedContacts,
  )

  const contactsFilteredByClientStatus = filterLandlordsBySentimentStatus(
    filterByClientStatus as SentimentStatusSelectOptions,
    contactsFilteredByFlag,
  )

  const reSortedContacts = sortActiveESIRecords(
    contactsFilteredByClientStatus,
    sortByValues,
    isMemberView,
  )

  return reSortedContacts
}

const filterContactsByEmailStatus = <
  T extends {
    emails?: {
      flaggedStatus?: EmailFlagStatus
    }[]
  },
>(
  contacts: T[],
  statuses: SentimentAnalysisFlagOptions[],
): T[] => {
  return contacts.filter((contact) => {
    const emails = contact.emails
    const matchingEmails = emails?.filter((email) =>
      statuses.includes(email?.flaggedStatus as SentimentAnalysisFlagOptions),
    )
    return matchingEmails && matchingEmails.length > 0
  })
}

export const filterEmailsByFlag = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      csiRating?: number
    }[]
  },
>(
  filterByFlag: SentimentAnalysisFlagOptions,
  formattedContacts: T[],
): T[] => {
  switch (filterByFlag) {
    case SentimentAnalysisFlagOptions.ALL:
      return formattedContacts

    case SentimentAnalysisFlagOptions.FLAGGED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.FLAGGED,
      ])

    case SentimentAnalysisFlagOptions.REVIEWED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.APPROVED,
        SentimentAnalysisFlagOptions.REJECTED,
      ])

    case SentimentAnalysisFlagOptions.APPROVED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.APPROVED,
      ])

    case SentimentAnalysisFlagOptions.REJECTED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.REJECTED,
      ])

    case SentimentAnalysisFlagOptions.NOT_FLAGGED:
      return filterContactsByEmailStatus(formattedContacts, [
        SentimentAnalysisFlagOptions.NOT_FLAGGED,
      ])

    default:
      return formattedContacts
  }
}

export const filterLandlordsBySentimentStatus = <
  T extends {
    id: number
    emails?: {
      csiRating?: number
      flaggedStatus?: EmailFlagStatus
    }[]
    landlordsWithEmailsAndSentimentScores?: {
      csiRating?: number
      sentimentStatus?: SentimentStatusOptions
    }[]
  },
>(
  filterByClientStatus: SentimentStatusSelectOptions,
  formattedContacts: T[],
): T[] => {
  switch (filterByClientStatus) {
    case SentimentStatusSelectOptions.ALL:
      return formattedContacts

    case SentimentStatusSelectOptions.AT_RISK:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.AT_RISK,
      )

    case SentimentStatusSelectOptions.IN_REVIEW:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.IN_REVIEW,
      )

    case SentimentStatusSelectOptions.NOT_IN_REVIEW:
      return filterLandlordsByClientSentimentStatus(
        formattedContacts,
        SentimentStatusSelectOptions.NOT_IN_REVIEW,
      )

    default:
      return formattedContacts
  }
}

const filterLandlordsByClientSentimentStatus = <
  T extends {
    landlordsWithEmailsAndSentimentScores?: {
      sentimentStatus?: SentimentStatusOptions
    }[]
    sentimentStatus?: SentimentStatusOptions
  },
>(
  contacts: T[],
  sentimentStatus: SentimentStatusSelectOptions,
): T[] => {
  return contacts.filter((contact) => {
    const landlords = contact.landlordsWithEmailsAndSentimentScores
    const landlordSentimentStatus = contact.sentimentStatus

    // Check if the contact matches the sentiment status
    const matchingContact = landlordSentimentStatus === sentimentStatus

    // Check if landlords match the sentiment status
    const matchingLandlords = landlords?.filter(
      (landlord) => landlord?.sentimentStatus === sentimentStatus,
    )

    // Combine the conditions: either the contact matches, or at least one landlord matches
    return (
      matchingContact || (matchingLandlords && matchingLandlords.length > 0)
    )
  })
}

export const getStatusColor = (
  sentimentStatus: SentimentStatusOptions,
): string => {
  if (sentimentStatus === 'IN_REVIEW') {
    return 'text-yellow-600 bg-yellow-100'
  } else if (sentimentStatus === 'AT_RISK') {
    return 'text-red-600 bg-red-100'
  } else {
    return 'text-gray-600 bg-slate-100'
  }
}
