import { FC } from 'react'

import {
  ArrowTopRightOnSquareIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import { Avatar, Chip, Tooltip } from '@mui/material'

import Button from 'src/components/Library/Button/Button'

import {
  SentimentAnalysisItemHeaderProps,
  SentimentAnalysisLabelThemes,
  SentimentAnalysisMetric,
  SentimentAnalysisMetricName,
  SentimentAnalysisStatusOptionsDisplayMap,
} from '../SentimentAnalysisHelper'
import { getRatingStatus, getStatusColor } from '../SentimentAnalysisUtils'

import SentimentAnalysisBadge from './SentimentAnalysisBadge'

/**
 * ⚠️ This component is used by both the Member and Admin Sentiment pages, but in different contexts.
 *
 * Member: Used for Landlord details
 * Admin: Used for Member details
 */
const SentimentAnalysisItemHeader: FC<SentimentAnalysisItemHeaderProps> = ({
  propertyOwner,
  hideEmailScore = false,
  avatarUrl = '',
  totalEmails,
  inboxEmail,
  isClient = false,
  isLoading,
  handleClickOpen,
}) => {
  const csiRating = Math.round(propertyOwner?.csiRating * 100) / 100

  const labelTheme = getRatingStatus(csiRating)

  return (
    <div className="row flex w-full flex-wrap items-center justify-between gap-4">
      <div className="flex items-center gap-2">
        {avatarUrl && <Avatar className="h-8 w-8" src={avatarUrl} />}
        <span className="mr-4 text-sm uppercase">{propertyOwner?.name}</span>
        <span
          className={`mr-10 text-xs text-gray-500 ${
            hideEmailScore ? '' : 'lowercase'
          }`}
        >
          {propertyOwner?.emailCensored}
        </span>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {inboxEmail && (
          <Tooltip
            title={'Open in Outlook, this requires access to the email account'}
            placement="top"
            arrow
          >
            <div>
              <a
                href={`https://outlook.office.com/mail/${inboxEmail}/`}
                target="_blank"
                rel="noreferrer"
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-500" />
              </a>
            </div>
          </Tooltip>
        )}
        {isClient && (
          <div className="flex items-center gap-2">
            <Button
              size="small"
              className="max-h-[28px] max-w-[160px] flex-shrink rounded-md bg-indigo-100 text-indigo-500 shadow-sm hover:bg-indigo-200"
              loading={isLoading.id === propertyOwner.id && isLoading.status}
              onClick={(event) => handleClickOpen(event, propertyOwner.id)}
              endIcon={<PaperAirplaneIcon className="h-4 w-4" />}
            >
              Send to HubDash
            </Button>

            <Chip
              label={
                SentimentAnalysisStatusOptionsDisplayMap[
                  propertyOwner.sentimentStatus
                ]
              }
              size="small"
              className={`h-[28px] w-[120px] text-sm ${getStatusColor(propertyOwner.sentimentStatus)}`}
            />
          </div>
        )}
        <SentimentAnalysisBadge
          title="Emails:"
          value={propertyOwner?.emails?.length || totalEmails}
          variant={SentimentAnalysisLabelThemes.default}
          className="w-[120px]"
        />

        <span className="mr-4 flex">
          <Tooltip
            title={<p className="text-sm">{SentimentAnalysisMetricName.esi}</p>}
            arrow
            placement="top"
          >
            <div>
              <SentimentAnalysisBadge
                title={`${SentimentAnalysisMetric.esi}:`}
                value={csiRating}
                variant={labelTheme}
                className="w-[120px]"
              />
            </div>
          </Tooltip>
        </span>
      </div>
    </div>
  )
}

export default SentimentAnalysisItemHeader
