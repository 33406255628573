import React, { type FC, useEffect, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from '@mui/material'
import type {
  CreateOrUpdateLandlordFlaggedEmail,
  CreateOrUpdateLandlordFlaggedEmailVariables,
  UpdateLandlordFlaggedEmailInput,
  LandlordEmail,
  ArchiveFlaggedEmail,
  ArchiveFlaggedEmailVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import {
  ARCHIVE_FLAGGED_EMAIL,
  UPDATE_FEEDBACK,
} from 'src/components/SentimentAnalysis/SentimentAnalysisQueries'

export const FLAG_EMAIL_FOR_REVIEW = gql`
  mutation CreateOrUpdateLandlordFlaggedEmail(
    $input: CreateOrUpdateLandlordFlaggedEmailInput!
  ) {
    createOrUpdateLandlordFlaggedEmail(input: $input) {
      id
    }
  }
`

interface FlaggedEmailUpdateOrDeleteModalProps {
  isOpen: boolean
  onClose: () => void
  email: LandlordEmail
  flaggedEmailFeedback: string
  setFlaggedEmailFeedback: (value: string) => void
  loading: boolean
  setLoading: (value: boolean) => void
  isAdminView?: boolean
}

const FlaggedEmailUpdateOrDeleteModal: FC<
  FlaggedEmailUpdateOrDeleteModalProps
> = ({
  isOpen,
  onClose,
  email,
  flaggedEmailFeedback,
  setFlaggedEmailFeedback,
  loading,
  setLoading,
  isAdminView,
}) => {
  const [updating, setUpdating] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [flagEmailForReview] = useMutation<
    CreateOrUpdateLandlordFlaggedEmail,
    CreateOrUpdateLandlordFlaggedEmailVariables
  >(FLAG_EMAIL_FOR_REVIEW, {
    onCompleted: () => {
      onClose()
      setLoading(false)
      toast.success('Email flagged for review successfully')
    },
    onError: (error) => {
      toast.error(error.message, { duration: 5000 })
      setLoading(false)
    },
    refetchQueries: ['FindSentimentAnalysisQuery', 'SentimentEmailsQuery'],
    awaitRefetchQueries: true,
  })

  const [updateFlaggedEmailFeedback] =
    useMutation<UpdateLandlordFlaggedEmailInput>(UPDATE_FEEDBACK, {
      onCompleted: () => {
        onClose()
        setUpdating(false)
        toast.success('Email flag updated successfully')
      },
      onError: (error) => {
        toast.error(error.message)
        setUpdating(false)
      },
      refetchQueries: ['FindSentimentAnalysisQuery', 'SentimentEmailsQuery'],
      awaitRefetchQueries: true,
    })

  const [deleteFeedback] = useMutation<
    ArchiveFlaggedEmail,
    ArchiveFlaggedEmailVariables
  >(ARCHIVE_FLAGGED_EMAIL, {
    onCompleted: () => {
      onClose()
      setDeleting(false)
      toast.success('Flagged email removed successfully')
    },
    onError: () => {
      setDeleting(false)
      toast.error('Error removing feedback')
    },
    refetchQueries: ['FindSentimentAnalysisQuery', 'SentimentEmailsQuery'],
    awaitRefetchQueries: true,
  })

  const handleFlagEmailForReview = (email: LandlordEmail) => {
    flagEmailForReview({
      variables: {
        input: {
          landLordEmailId: email.id,
          feedback: flaggedEmailFeedback,
          isAdmin: isAdminView,
        },
      },
    })
  }

  const handleUpdateFeedback = (email: LandlordEmail) => {
    updateFlaggedEmailFeedback({
      variables: {
        id: email.flaggedEmail.id,
        input: {
          feedback: flaggedEmailFeedback,
        },
      },
    })
  }

  useEffect(() => {
    if (email?.flaggedEmail?.feedback !== flaggedEmailFeedback) {
      setFlaggedEmailFeedback(email?.flaggedEmail?.feedback)
    }
  }, [email?.flaggedEmail?.feedback])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <div className="flex flex-col gap-2">
          Flag Email For Review{' '}
          <p className="text-sm text-gray-600">
            By confirming, this email will be flagged and sent for review.
          </p>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="mt-4">
          <p className={'mb-1 text-sm text-gray-500'}>Feedback</p>
          <TextareaAutosize
            className={
              'text- w-full rounded border border-gray-300 bg-gray-100 p-2'
            }
            minRows={3}
            placeholder="Enter your feedback here..."
            value={flaggedEmailFeedback}
            onChange={(e) => setFlaggedEmailFeedback(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions className="flex flex-row pb-5">
        <div className={'flex w-full justify-between px-4'}>
          <Button
            fullWidth={false}
            variant={'outlined'}
            color={'secondary'}
            onClick={onClose}
          >
            Close
          </Button>
          <div className="flex gap-2">
            {email?.flaggedEmail?.feedback ? (
              <>
                <Button
                  fullWidth={false}
                  variant={'contained'}
                  color={'error'}
                  startIcon={<TrashIcon className={'h-4 w-4'} />}
                  loading={deleting}
                  onClick={() => {
                    setDeleting(true)
                    deleteFeedback({ variables: { id: email.flaggedEmail.id } })
                  }}
                >
                  Remove
                </Button>
                <Button
                  fullWidth={false}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    setUpdating(true)
                    handleUpdateFeedback(email)
                  }}
                  loading={updating}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                fullWidth={false}
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  setLoading(true)
                  handleFlagEmailForReview(email)
                }}
                disabled={!flaggedEmailFeedback}
                loading={loading}
              >
                Confirm
              </Button>
            )}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default FlaggedEmailUpdateOrDeleteModal
