import { FC, useEffect, useMemo, useState } from 'react'

import {
  ArrowDownIcon,
  ArrowUpIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  MinusIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline'
import { POLARITY } from 'api/src/common/enums'
import {
  calculatePercentageOf,
  checkSentimentScorePolarity,
  Rounding,
} from 'api/src/common/utils'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-4'

import {
  SABucketOptions,
  SentimentAnalysisGraphProps,
  SentimentAnalysisMetric,
} from '../SentimentAnalysisHelper'

import {
  getDataFilledChartConfig,
  getSentimentGraphOptions,
  MIN_NUMBER_OF_NODES,
} from './SentimentAnalysisGraphConfig'
import SentimentAnalysisGraphTile from './SentimentAnalysisGraphTile'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
)

const SentimentAnalysisGraph: FC<SentimentAnalysisGraphProps> = ({
  emails,
  unprocessedEmailsCount,
  hideGraph = false,
  tileTitle = '',
  filterDateRange = 'ALL',
  selectedBucket = SABucketOptions.DAY,
}) => {
  const values = emails?.map((email) => email?.csiRating)
  const [sentimentGraphOptions, setSentimentGraphOptions] = useState(
    getSentimentGraphOptions(
      SentimentAnalysisMetric.esi,
      filterDateRange,
      selectedBucket,
    ),
  )
  const [sentimentGraphData, setSentimentGraphData] = useState(
    getDataFilledChartConfig(filterDateRange, emails, selectedBucket),
  )

  const valuesASC = [...values].sort((a, b) => {
    if (a < b) return 1

    if (a > b) return -1

    return 0
  })

  useEffect(() => {
    setSentimentGraphOptions(
      getSentimentGraphOptions(
        SentimentAnalysisMetric.esi,
        filterDateRange,
        selectedBucket,
      ),
    )
    setSentimentGraphData(
      getDataFilledChartConfig(filterDateRange, emails, selectedBucket),
    )
  }, [filterDateRange, selectedBucket])

  const positiveValues = values.filter((score) => {
    return checkSentimentScorePolarity(score) === POLARITY.POSITIVE
  })

  const negativeValues = values.filter((score) => {
    return checkSentimentScorePolarity(score) === POLARITY.NEGATIVE
  })

  const graphTiles = useMemo(() => {
    const positiveEmailsPercent = calculatePercentageOf(
      0,
      positiveValues.length,
      values.length,
      Rounding.Round,
    )

    const negativeEmailsPercent = calculatePercentageOf(
      0,
      negativeValues.length,
      values.length,
      Rounding.Round,
    )

    const neutralEmails = 100 - positiveEmailsPercent - negativeEmailsPercent

    return [
      {
        title: 'Highest Score',
        value: Math.round(valuesASC.at(0) * 100) / 100,
        icon: (
          <ArrowUpIcon className="h-10 w-10 rounded-full bg-green-100 p-2 text-green-600" />
        ),
      },

      {
        title: 'Lowest Score',
        value: Math.round(valuesASC.at(-1) * 100) / 100,
        icon: (
          <ArrowDownIcon className="h-10 w-10 rounded-full bg-red-100 p-2 text-red-600" />
        ),
      },
      {
        title: 'Unprocessed',
        value: unprocessedEmailsCount,
        icon: (
          <NoSymbolIcon className="h-10 w-10 rounded-full bg-gray-100 p-2 text-gray-600" />
        ),
      },
      {
        title: 'Positive',
        value: positiveEmailsPercent + '%',
        icon: (
          <HandThumbUpIcon className="h-10 w-10 rounded-full bg-green-100 p-2 text-green-600" />
        ),
      },
      {
        title: 'Negative',
        value: negativeEmailsPercent + '%',
        icon: (
          <HandThumbDownIcon className="h-10 w-10 rounded-full bg-red-100 p-2 text-red-600" />
        ),
      },
      {
        title: 'Neutral',
        value: neutralEmails + '%',
        icon: (
          <MinusIcon className="h-10 w-10 rounded-full bg-yellow-100 p-2 text-yellow-600" />
        ),
      },
    ]
  }, [positiveValues.length, values.length, valuesASC])

  return (
    <div className="w-full max-w-[580px] p-4">
      {!hideGraph && (
        <div className="mb-2 rounded border border-gray-200 p-4">
          {tileTitle && (
            <div className="flex grow justify-around">
              <span>{tileTitle}</span>
            </div>
          )}
          <div className="grow">
            {values.length > MIN_NUMBER_OF_NODES && (
              <Line options={sentimentGraphOptions} data={sentimentGraphData} />
            )}
            {values.length <= MIN_NUMBER_OF_NODES && (
              <div className="py-24 text-center text-sm font-light text-gray-400">
                There is not enough information to populate the chart.
              </div>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-3 gap-2">
        {graphTiles.map((tile, index) => (
          <SentimentAnalysisGraphTile
            key={index}
            value={tile.value}
            title={tile.title}
            icon={tile.icon}
          />
        ))}
      </div>
    </div>
  )
}

export default SentimentAnalysisGraph
